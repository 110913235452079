.panel {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.075);
  border-radius: 0;
  border: 0;
  margin-bottom: 15px; }
  .panel hr {
    border-color: rgba(0, 0, 0, 0.1); }
  .panel .panel-bg-cover {
    max-height: 180px;
    overflow: hidden; }
    .panel .panel-bg-cover img {
      min-width: 100%;
      min-height: 100%;
      background-size: cover; }
  .panel.remove {
    opacity: 0;
    transition: opacity, 0.5s; }
  .panel .alert {
    border-radius: 0; }

.panel.panel-bg-img {
  position: relative; }

.panel .panel-bg-wrap {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .panel .panel-bg-wrap > img {
    position: absolute;
    top: 0;
    left: 0; }
  .panel .panel-bg-wrap + .panel-body {
    position: relative; }

.panel-media {
  box-shadow: 0 -50px 20px -10px rgba(0, 0, 0, 0.2);
  padding: 10px 15px 15px 140px;
  position: relative; }
  .panel-media-img {
    position: absolute;
    width: 96px;
    height: 96px;
    left: 20px;
    top: -48px; }
  .panel-media-heading {
    color: #fff;
    position: absolute;
    top: -2.7em; }

.panel .panel-heading,
.panel > :first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.panel .panel-footer,
.panel > :last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

.panel-body-full {
  margin-left: -20px;
  margin-right: -20px; }

.panel-body {
  padding: 25px 20px; }
  .panel-body .row, .panel-body .form-horizontal .form-group {
    margin-left: -12.5px;
    margin-right: -12.5px; }
  .panel-body > .row, .panel-body > [class^="form-"] > .row {
    margin: 0; }
    .panel-body > .row > [class^="col-"]:first-child, .panel-body > [class^="form-"] > .row > [class^="col-"]:first-child {
      padding-left: 0; }
    .panel-body > .row > [class^="col-"]:last-child, .panel-body > [class^="form-"] > .row > [class^="col-"]:last-child {
      padding-right: 0; }

.panel-trans {
  border-color: transparent;
  box-shadow: none;
  background-color: transparent; }

.panel-heading {
  position: relative;
  height: 50px;
  padding: 0; }

.panel-title {
  font-weight: normal;
  padding: 0 20px 0 20px;
  font-size: 1.416em;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.panel-default.panel-colorful {
  background-color: #e0e5ec;
  color: #515151; }

.panel-default .panel-heading {
  background-color: #e0e5ec;
  border-color: #f0f2f6; }

.panel-footer {
  background-color: #f4f5f7;
  color: #5f5f5f;
  border-color: #eef0f2;
  position: relative; }

/* Colors */
/*---------------------------------*/
.panel-primary .panel-heading,
.panel-primary .panel-footer, .panel-primary.panel-colorful {
  background-color: #5fa2dd;
  border-color: #5fa2dd;
  color: #ffffff; }

.panel-info .panel-heading,
.panel-info .panel-footer, .panel-info.panel-colorful {
  background-color: #46bbdc;
  border-color: #46bbdc;
  color: #ffffff; }

.panel-success .panel-heading,
.panel-success .panel-footer, .panel-success.panel-colorful {
  background-color: #91c957;
  border-color: #91c957;
  color: #ffffff; }

.panel-warning .panel-heading,
.panel-warning .panel-footer, .panel-warning.panel-colorful {
  background-color: #f1aa40;
  border-color: #f1aa40;
  color: #ffffff; }

.panel-danger .panel-heading,
.panel-danger .panel-footer, .panel-danger.panel-colorful {
  background-color: #f76549;
  border-color: #f76549;
  color: #ffffff; }

.panel-mint .panel-heading,
.panel-mint .panel-footer, .panel-mint.panel-colorful {
  background-color: #42cca5;
  border-color: #42cca5;
  color: #ffffff; }

.panel-purple .panel-heading,
.panel-purple .panel-footer, .panel-purple.panel-colorful {
  background-color: #9f5594;
  border-color: #9f5594;
  color: #ffffff; }

.panel-pink .panel-heading,
.panel-pink .panel-footer, .panel-pink.panel-colorful {
  background-color: #e17ca7;
  border-color: #e17ca7;
  color: #ffffff; }

.panel-dark .panel-heading,
.panel-dark .panel-footer, .panel-dark.panel-colorful {
  background-color: #33373a;
  border-color: #33373a;
  color: #ffffff; }

.panel > .panel-heading:after,
.panel.panel-colorful > .panel-heading:after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  left: 20px;
  right: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.panel-colorful > .panel-heading {
  border: 0; }

.panel-default > .panel-heading:after, .panel-primary > .panel-heading:after, .panel-info > .panel-heading:after,
.panel-success > .panel-heading:after, .panel-warning > .panel-heading:after, .panel-danger > .panel-heading:after,
.panel-purple > .panel-heading:after, .panel-pink > .panel-heading:after, .panel-dark > .panel-heading:after {
  display: none; }

/* Bordered */
/*---------------------------------*/
.panel-bordered-default, .panel-default.panel-bordered {
  border: 1px solid #b3c1d1; }

.panel-bordered-primary, .panel-primary.panel-bordered {
  border: 1px solid #5fa2dd; }

.panel-bordered-info, .panel-info.panel-bordered {
  border: 1px solid #46bbdc; }

.panel-bordered-success, .panel-success.panel-bordered {
  border: 1px solid #91c957; }

.panel-bordered-warning, .panel-warning.panel-bordered {
  border: 1px solid #f1aa40; }

.panel-bordered-danger, .panel-danger.panel-bordered {
  border: 1px solid #f76549; }

.panel-bordered-mint, .panel-mint.panel-bordered {
  border: 1px solid #42cca5; }

.panel-bordered-purple, .panel-purple.panel-bordered {
  border: 1px solid #9f5594; }

.panel-bordered-pink, .panel-pink.panel-bordered {
  border: 1px solid #e17ca7; }

.panel-bordered-dark, .panel-dark.panel-bordered {
  border: 1px solid #33373a; }

/* Panel Group */
/*---------------------------------*/
.panel-group .panel {
  border-radius: 0;
  margin-bottom: 20px; }

.panel-group > div {
  padding-left: 0;
  padding-right: 0; }
  .panel-group > div:first-child > .panel {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .panel-group > div:last-child > .panel {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .panel-group > div + div > .panel {
    margin-left: -1px; }

/* Panel Control */
/*---------------------------------*/
.panel-control {
  height: 100%;
  position: relative;
  float: right;
  padding: 0 15px; }
  .panel-control:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    left: -1em;
    position: relative; }
  .panel-control > i,
  .panel-control > .badge,
  .panel-control > label {
    vertical-align: middle; }

/* Panel with tabs */
/*---------------------------------*/
.panel-control .nav-tabs {
  display: inline-block;
  height: 40px;
  margin-top: 10px;
  vertical-align: bottom;
  border: 0; }
  .panel-control .nav-tabs > li {
    margin-top: 1px;
    margin-right: 5px;
    height: 100%; }
    .panel-control .nav-tabs > li > a {
      border-radius: 0;
      margin-right: 0;
      height: 100%;
      line-height: 40px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 0 15px; }
    .panel-control .nav-tabs > li:not(.active) {
      background-color: transparent;
      opacity: .6; }
      .panel-control .nav-tabs > li:not(.active) a {
        color: inherit;
        border-bottom: 0 !important; }
        .panel-control .nav-tabs > li:not(.active) a:hover {
          background-color: rgba(0, 0, 0, 0.15);
          border-color: transparent; }
  .panel-control .nav-tabs > .active > a {
    z-index: 1; }

/* Panel with progress bar */
/*---------------------------------*/
.panel-control .progress {
  min-width: 150px;
  margin: 0;
  display: inline-block;
  vertical-align: middle; }

/* Panel with switch */
/*---------------------------------*/
.panel-control .switchery {
  margin-left: 15px;
  vertical-align: middle; }

/* Panel with pager */
/*---------------------------------*/
.panel-control .pager {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0; }

/* Panel with pagination */
/*---------------------------------*/
.panel-control .pagination {
  margin: 0;
  border-radius: 0;
  vertical-align: middle; }
  .panel-control .pagination > li > a, .panel-control .pagination > li > span {
    padding: 0 10px;
    border: 0 0 0 0;
    border-color: rgba(0, 0, 0, 0.09);
    box-shadow: none;
    height: 100%;
    line-height: 30px; }
  .panel-control .pagination > li:not(.active):not(.disabled) > a:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.09); }
  .panel-control .pagination > .disabled > a, .panel-control .pagination > .disabled > a:hover, .panel-control .pagination > .disabled > a:active {
    border-color: rgba(0, 0, 0, 0.09); }

.panel-control .pagination > li:not(.active) > a, .pagination > li > a {
  background-color: transparent;
  color: inherit; }

.panel-control .pagination > li > a:hover, .pagination > li > a:focus {
  box-shadow: none; }

/* Panel with pagination */
/*---------------------------------*/
.panel-control .btn,
.panel-control .dropdown-toggle.btn {
  border: 0; }

.panel-control .open > .btn,
.panel-control .btn.active,
.panel-control .btn:active {
  box-shadow: none !important; }

.panel-control .btn-default {
  background-color: transparent;
  color: inherit; }

.panel-control > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.panel-control > .btn:last-child,
.panel-control > .btn-group:last-child > .btn:first-child {
  border-bottom-right-radius: 0; }

body {
  background-color: #ededed;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #58585a; }
  body:not(.home) #top-navigation {
    margin-bottom: 30px;
    box-shadow: 0px 1px 1px rgba(17, 17, 17, 0.5);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

/**
* Navigation
*/
#top-navigation {
  background-color: #ededed;
  margin-bottom: 0px;
  border-bottom: none; }
  #top-navigation .navbar-header .navbar-brand {
    height: auto; }
    #top-navigation .navbar-header .navbar-brand img {
      width: 220px; }
      @media (min-width: 768px) {
        #top-navigation .navbar-header .navbar-brand img {
          width: 340px; } }
  #top-navigation .menu-block {
    display: block;
    clear: right; }
    #top-navigation .menu-block.menu-top > ul > li > a {
      padding: 9px 15px; }
    #top-navigation .menu-block.menu-top > ul > li:not(:last-child):after {
      color: #777;
      content: "|";
      position: absolute;
      right: -4px;
      top: 8px; }
    #top-navigation .menu-block.menu-bottom > ul > li > a {
      color: #f78f1e;
      padding: 9px 15px; }
      #top-navigation .menu-block.menu-bottom > ul > li > a:hover, #top-navigation .menu-block.menu-bottom > ul > li > a:focus {
        background-color: #fff; }
    #top-navigation .menu-block.menu-bottom > ul > li.open > a {
      background-color: #fff; }
    #top-navigation .menu-block.menu-bottom > ul > li:not(:last-child):after {
      color: #f78f1e;
      content: "|";
      position: absolute;
      right: -4px;
      top: 8px; }

/**
* Slider
*/
#slider-wrap {
  background-color: #fff; }
  @media (min-width: 768px) {
    #slider-wrap .slide {
      min-height: 240px; } }
  #slider-wrap .slide .slide-inner {
    position: relative; }
    #slider-wrap .slide .slide-inner .slider-text {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    #slider-wrap .slide .slide-inner .slider-img {
      max-height: 240px;
      opacity: 0.3; }
      @media (min-width: 768px) {
        #slider-wrap .slide .slide-inner .slider-img {
          opacity: 1; } }

/**
* Buttons
*/
.btn {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .btn.btn-primary {
    background-color: #ee8f1e;
    border: 1px solid #b2670d;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    color: #fff; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #ca750f; }

/**
* Text headers
*/
h2 {
  font-size: 16px; }
  @media (min-width: 768px) {
    h2 {
      font-size: 44px; } }

h5 {
  font-size: 11px;
  font-weight: bold; }

/**
* Forms
*/
.panel-body form.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0; }

form > .form-group:last-child {
  margin-bottom: 0; }

.input-group label.error {
  position: absolute;
  left: 0;
  bottom: -22px; }

.form-control {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.login-form ul {
  padding-left: 15px; }
  .login-form ul > li > a {
    padding: 0; }

label.error {
  font-size: 11px;
  color: #ff6161; }

.form-group.well {
  padding: 15px 0px; }

/**
* Links
*/
a:link, a:visited,
.btn-link:link,
.btn-link:visited {
  color: #58585a; }

a:hover, a:focus,
.btn-link:hover,
.btn-link:focus {
  text-decoration: none;
  color: #ff9f38; }

@media (min-width: 768px) {
  #home-content .panel .panel-body {
    min-height: 305px; } }

.quick-access {
  list-style: none;
  padding-left: 0; }
  .quick-access > li {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 5px;
    padding-bottom: 5px; }
    .quick-access > li > a {
      display: block;
      font-size: 16px; }
      .quick-access > li > a i {
        width: 50px;
        color: #ff9f38;
        font-size: 20px; }
    .quick-access > li:last-child {
      border-bottom: none; }

/*
* Page
*/
.page {
  padding-bottom: 22px; }
  .page .page-title {
    color: #f79020;
    margin-bottom: 25px; }

/**
* Panels
*/
.panel {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .panel.panel-default .panel-heading {
    background-color: #e7e7e7; }
  .panel.panel-default .panel-body {
    background-color: #f5f5f5; }
  .panel.panel-primary {
    border-color: #f79020; }
    .panel.panel-primary .panel-heading {
      background-color: #f79020;
      color: #58585a;
      border-color: #f79020;
      border-top-right-radius: 0;
      border-top-left-radius: 0; }

.panel-group.accordion .panel-title {
  line-height: 18px;
  font-size: 110%; }

.panel-group.accordion .panel .panel-heading {
  height: auto;
  padding-top: 7px;
  padding-bottom: 7px; }

/**
* Lists
*/
.list-circle > li {
  list-style-type: circle; }

.list-numbered {
  padding-left: 25px;
  list-style-type: none; }
  .list-numbered > li {
    margin-bottom: 15px; }

/**
* Footer
*/
footer {
  background-color: #dedede;
  padding: 15px 0 0;
  font-size: 11px; }
  footer .footer-top ul {
    padding-left: 0; }
    footer .footer-top ul > li > a {
      font-size: 11px; }
  footer ul.socials > li > a {
    font-size: 25px;
    vertical-align: top; }
    footer ul.socials > li > a:hover, footer ul.socials > li > a:focus {
      opacity: 0.7; }
  footer ul.socials > li.ln > a {
    color: #0077b5; }
  footer ul.socials > li.fb > a {
    color: #3b5998; }
  footer ul.socials > li.tw > a {
    color: #0084b4; }
  footer address {
    margin-bottom: 0; }
  footer .footer-bottom {
    padding-top: 15px; }
  footer .copyright {
    border-top: 1px solid;
    border-top-color: #f4f4f4;
    box-shadow: 0px -1px 0px #a0a0a0;
    overflow: hidden;
    padding-top: 10px; }
    footer .copyright p {
      display: inline-block; }
    footer .copyright ul li {
      border-right: 1px solid #979797; }
      footer .copyright ul li:last-child {
        border-right: none; }

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100; }

.panel-overlay-wrap {
  position: relative; }

.panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  z-index: 795;
  height: 100%;
  width: 100%; }
  .panel-overlay:before {
    content: "";
    display: inline-block;
    height: 100%;
    width: 1px;
    vertical-align: middle;
    margin-left: -5px; }

.panel-overlay-title {
  margin: 10px 0 5px; }

.panel-overlay-icon {
  display: inline-block;
  vertical-align: middle; }

.panel-overlay-content {
  display: inline-block;
  vertical-align: middle; }

.tooltip {
  width: 250px;
  padding: 20px; }
  .tooltip.in {
    opacity: 1; }
  .tooltip .tooltip-inner {
    background-color: #e6ddcd;
    color: #111;
    padding: 10px 19px; }

.radio-box {
  margin-bottom: 10px;
  background: #fff;
  border: 1px solid rgba(255, 165, 0, 0.49);
  border-radius: 3px; }
  .radio-box:hover, .radio-box:focus, .radio-box.active {
    background-color: rgba(255, 165, 0, 0.49); }
  .radio-box label {
    display: block; }
    .radio-box label:hover, .radio-box label:focus {
      cursor: pointer; }
  .radio-box input {
    display: none; }
  .radio-box span {
    display: block; }
  .radio-box .service-date,
  .radio-box .service-time {
    font-size: 11px; }
  .radio-box .radio-details {
    padding: 10px; }
    .radio-box .radio-details hr {
      margin-top: 7px;
      margin-bottom: 7px; }

/**
* Misc
*/
.bordered-right {
  border-right: 1px solid rgba(0, 0, 0, 0.05); }

@media (min-width: 768px) {
  .bordered-left {
    border-left: 1px solid;
    border-left-color: #f4f4f4;
    box-shadow: -1px 0px 0px #a0a0a0; } }

ul.unstyled {
  list-style: none; }

.text-orange {
  color: #f78f1e; }

.marg-top {
  margin-top: 15px !important; }

.marg-btm {
  margin-bottom: 15px !important; }

.marg-rgt {
  margin-right: 15px !important; }

.marg-left {
  margin-left: 15px !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.font-weight-light {
  font-weight: normal !important; }

.titleborder {
  border-bottom: 1px solid;
  clear: both;
  border-bottom-color: #c9c9c7;
  box-shadow: 0px 1px 0px #f4f4f4; }

.border-left {
  border-left: 1px solid;
  clear: both;
  border-left-color: #c9c9c7; }

#billing_info {
  display: none; }

.tab-content.bordered {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd; }

.tab-content .tab-content-inner {
  padding: 20px; }

label.checkbox {
  padding-top: 0 !important;
  line-height: 15px; }
  label.checkbox input {
    box-shadow: none;
    margin-top: -5px; }
  label.checkbox span {
    vertical-align: middle;
    display: inline-block;
    line-height: 15px;
    margin-left: 5px;
    font-weight: normal;
    font-size: 12px; }

div[class*="col-"].no-pad-2 {
  padding-left: 5px;
  padding-right: 5px; }

div[class*="col-"].no-pad-r {
  padding-right: 5px; }

a[data-toggle="tooltip"] {
  font-size: 11px; }

.mar-bot-no {
  margin-bottom: 0; }

.get-freight label.checkbox-inline {
  font-size: 12px; }

.get-freight .form-control {
  font-size: 11px; }

.btn-auth {
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

small {
  font-size: 75% !important; }

/*# sourceMappingURL=styles.css.map */
